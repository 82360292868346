// @ts-nocheck
'use client'
import React from "react";
import error_computer from '@/public/assets/images/error_computer.svg';
import { Button, ButtonGroup } from "@nextui-org/button";
import Link from "next/link";
import Image from 'next/image';
import { Config } from "@/src/constant";

export default function Error404(){
  const {phone} = Config;

  return (
    <div className="bg-background flex flex-col justify-center items-center self-start md:basis-3/4 md:w-3/4 w-full">
      <Image src={error_computer} alt={"404 not found"}/>
      <div className="text-center">
        <h3 className="md:text-4xl text-xl font-semibold mb-2">404 không tìm thấy</h3>
        <h1 className="md:text-5xl text-2xl font-bold mb-4">Rất tiếc! Trang đó không tồn tại.</h1>
        <p className="md:text-2xl text-md mb-4">Dưới đây là một số liên kết hữu ích thay thế:</p>
        <div className="flex gap-4 justify-center mb-4">
          <ButtonGroup>
            <Button href="/" as={Link} color="primary">Home</Button>
            <Button href="/tim-phim" as={Link} color="primary">Search</Button>
            <Button href={`https://t.me/${phone}`} target="_blank" as={Link} color="primary">Contact</Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};
